// @ts-nocheck
import { listBlockAdapter } from './listBlockAdapter'
import Encryption from '../../../helpers/encryption'
import { sectionDataAdapter } from './sectionDataAdapter'

export const sectionFormAdapter = (data: any) => {
  const {
    subtitle,
    formType,
    isDarkTheme,
    formTitle,
    formTitleEng,

    formTitleSecondPart,
    formTitleSecondPartEng,
    sectionFormDescriptionSecondPart,
    sectionFormDescriptionSecondPartEng,
    switchLangLink,
    switchLangLinkEng,
    switchLangLinkText,
    switchLangLinkTextEng,
    contacts: [contacts] = [null],
    successMessage,
    successMessageEng,
    sendButtonText,
    sendButtonTextEng,
    privacyTitle,
    privacyLinkUrl,
    privacyLinkLabel,
    policyAccept,
    eventCategory,
    eventAction,
    'id-recipients': idRecipients,
    'id-regionRecipients': idRegionRecipients,
    agentPhoneNumber,
    subjectEmailText,
    hideDefault,
    formStyle,
  } = data

  const encryption = new Encryption()
  const encrypted = encryption.encrypt(
    agentPhoneNumber,
    process.env.FORM_SMS_BY_TEL_SECRET
  )

  const isNewStyle = formStyle === 'Да'

  return {
    sectionData: sectionDataAdapter(data),
    subjectEmailText,
    idRecipients,
    idRegionRecipients,
    subtitle,
    eventCategory,
    eventAction,
    formType,
    formTitle,
    formTitleEng,
    formTitleSecondPart,
    formTitleSecondPartEng,
    sectionFormDescriptionSecondPart,
    sectionFormDescriptionSecondPartEng,
    switchLangLink,
    switchLangLinkEng,
    switchLangLinkText,
    switchLangLinkTextEng,
    isNewStyle,
    sendButtonText,
    sendButtonTextEng,
    successMessage,
    successMessageEng,
    privacyTitle,
    privacyLinkUrl,
    privacyLinkLabel,
    policyAccept,
    contacts: contacts && listBlockAdapter(contacts),
    isDarkTheme: isDarkTheme === 'Да',
    hideDefault: hideDefault === 'Да',
    agentPhoneNumber: encrypted,
  }
}
